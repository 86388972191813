import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/contact/hero"
import ThankYou from "./screens/contact/thank-you"

const ContactThanks = ({ location }) => {
  return (
    <React.Fragment>
      <Hero />
      {(!location.state)
        ?
        <ThankYou />
        :
        <ThankYou
          first_name={location.state.first_name}
        />
      }
    </React.Fragment>
  )
}
export default ContactThanks

export const Head = () => (
  <Seo
    title="Contact Us"
    description="Have a general inquiry or need customer support? Team Visby will be happy to field your inquiry here."
    image="/meta/contact.jpg"
  />
)